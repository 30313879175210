<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent
          class="py-8"
          title="Process Simulation Tests"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Process Simulation Tests Details"
              size="medium"
            />
            <a
              :href="
                require('../../assets/IPSeries/ProcessSimulationDetails-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/ProcessSimulationDetails-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light pb-6">
              This test is used to determine whether a material in a process
              configuration can transition from a burn type event (1.3) to a
              explosion type event (1.1). Substances are initiated in a process
              (ideally in a plausible worst case) configuration. Assessments can
              be made on the results of the test including the violence of the
              event (1.1, 1.3, etc), facility damage, and environmental risks.
              Note that changes in process configuration may warrant a retest.
            </p>
            <p class="p-light pb-6">
              Modeling can be coupled with small-scale tests to accurately
              estimate the outcome of initiation for the in-process scenario.
              Large scale in-process tests can be expensive. Coupling modeling
              with small-scale test results can accurately estimate the outcome
              including determining the necessary vent sizes and types in the
              process equipment. Small scale tests would include critical height
              and substance burn parameter determinations (amount of gas
              produced per kilogram, burn rate, etc.).
            </p>
          </div>
        </div>
        <div class="flex flex-wrap mt-8 pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Initial Reaction Example Video"
              size="medium"
            />
            <p class="p-light p-4">
              The below video is of simulated oven in with the same process
              configuration tested to determine the outcome should the oven be
              heated to the point of initiation of the contents.
            </p>
            <iframe
              src="https://player.vimeo.com/video/166132591?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166132591"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
            <TitleComponent
              class="pt-8"
              title="Modified Process Video"
              size="medium"
            />
            <p class="p-light p-4">
              Following the initial test of the simulated oven, a sprinkler
              system was installed to determine the outcome of the
              over-temperature initiation with the sprinkler system installed.
              It was desired to reduce the flames outside the oven. Below is the
              result.
            </p>
            <iframe
              src="https://player.vimeo.com/video/166132592?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166132592"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8 mb-3"
              title="Type Test Example Video"
              size="medium"
            />
            <p class="p-light px-4 pb-4">
              Below is a video of a hopper containing propellant in the
              in-process condition when the propellant is intentionally ignited.
              Such type testing is completed to determine the reaction violence
              in the in-process scenario. Note that different conditions (vent
              size, confinement, etc) as well as different propellants can yield
              different outcomes. In the case below, the reaction is a 1.3 type
              event. With other conditions or propellants, the reaction can be a
              1.1 event. Video provided by
              <a
                class="link"
                target="_blank"
                href="http://www.smsenergetics.com/"
                >Safety Management Services, Inc.</a
              >.
            </p>
            <iframe
              src="https://player.vimeo.com/video/144038343?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo144038343"
              allow="autoplay; fullscreen"
              id="player_3"
              name="fitvid2"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
